import React from "react"

export default function Tos() {
  return (
    <div className="mx-6">
      <h3 className="text-2xl font-medium font-heading my-3">
        Terms of Service for Quiz Me Ai.
      </h3>

      <div className="row features inner">
        <h4 className="text-xl font-medium font-heading text-gray-600 my-3">
          Terms & Conditions of Service
        </h4>
        Please reread these Terms and Conditions of Service (“Terms” or “Terms
        and Conditions”) carefully before using the Quiz Me Ai website, operated
        by Quiz Me Technologies Inc.; Quiz Me Ai For purposes of
        these Terms, “you” and “your” means you as the user of the Service. Your
        access to and use of the Service is conditioned upon your acceptance of
        and compliance with these Terms. These Terms apply to all visitors,
        users and others who wish to access or use the Service. By accessing or
        using the Service you agree to be bound by these Terms. If you disagree
        with any part of the terms, then you do not have permission to access
        the Service. If you are accessing or using the Service on behalf of a
        business or entity, then your business or entity is legally and
        financially responsible for your access to and use of the Service as
        well as for the use of your account by others affiliated with your
        entity, including any employees, agents or contractors.
        <h4 className="text-xl font-medium font-heading text-gray-600 my-3">
          Content
        </h4>
        Our Service allows you to create notes, attach images and other files
        such as documents, add links, on the Service. You are responsible for
        the Content that you post on or through the Service, including its
        legality, reliability, and appropriateness. Quiz Me Ai is committed to
        ensuring that the website is as useful and efficient as possible. For
        that reason, we reserve the right to make changes to the website or to
        charge for its services, at any time and for any reason. We will never
        charge you for the app or its services without making it very clear to
        you exactly what you’re paying for. By creating Content on or through
        the Service, You represent and warrant that: (a) the Content is yours
        and/or you have the right to use it (b) that the posting of your Content
        on or through the Service does not violate the privacy rights, publicity
        rights, copyrights, contract rights or any other rights of any person or
        entity. We reserve the right to terminate the account of anyone found to
        be infringing on a copyright. You retain any and all of your rights to
        any Content you submit, post or display on or through the Service and
        you are responsible for protecting those rights. We take no
        responsibility and assume no liability for Content you or any
        third-party posts on or through the Service. When you post Content you
        will be able to designate whether such Content is made publicly or
        privately available. You agree that Quiz Me Ai has the right, but not
        the obligation, to remove any Content from the Service if Quiz Me Ai
        determines in its sole discretion that such Content has violated these
        Terms, applicable law, or the privacy rights, publicity rights,
        intellectual property, contract rights or any other rights of any person
        or entity, or if Quiz Me Ai determines in its sole discretion that such
        Content poses a risk of harm to Quiz Me Ai, other users of the Service
        or third parties.
        <h4 className="text-xl font-medium font-heading text-gray-600 my-3">
          Accounts
        </h4>
        You are responsible for maintaining the confidentiality of your account
        and password, including but not limited to the restriction of access to
        your computer and/or account. You agree to accept responsibility for any
        and all activities or actions that occur under your account and/or
        password, whether your password is with our Service or a third-party
        service. You must notify us immediately upon becoming aware of any
        breach of security or unauthorized use of your account. You may not use
        as a username the name of another person or entity that is not lawfully
        available for use, or a name or trademark that is subject to any rights
        of another person or entity other than you, without appropriate
        authorization. You may not use as a username any name that is offensive,
        vulgar or obscene. You may not use the Service if you are 13 years of
        age or younger. By using the Service, you represent and warrant that you
        are over the age of 13.
        <h4 className="text-xl font-medium font-heading text-gray-600 my-3">
          Suggestions & Feedback
        </h4>
        We welcome feedback, comments and suggestions for improvements to the
        Service (“Feedback”). You acknowledge and expressly agree that any
        contribution of Feedback does not and will not give or grant you any
        right, title or interest in the Service or in any such Feedback. All
        Feedback becomes the sole and exclusive property of Quiz Me Ai, and Quiz
        Me Ai may use and disclose Feedback in any manner and for any purpose
        whatsoever without further notice or compensation to you and without
        retention by you of any proprietary or other right or claim. You hereby
        assign to Quiz Me Ai any and all right, title and interest (including,
        but not limited to, any patent, copyright, trade secret, trademark,
        show-how, know-how, moral rights and any and all other intellectual
        property right) that you may have in and to any and all Feedback.
        <h4 className="text-xl font-medium font-heading text-gray-600 my-3">
          Copyright
        </h4>
        We respect the intellectual property rights of others. It is our policy
        to respond to any claim that Content posted on the Service infringes on
        the copyright or other intellectual property rights (“Infringement”) of
        any person or entity. It is our policy, in appropriate circumstances and
        at our discretion, to disable or terminate the accounts of users who
        repeatedly Infringe copyrights or intellectual property rights of
        others. If you are a copyright owner, or authorized on behalf of one,
        and you believe that any content, materials or works uploaded,
        downloaded or appearing on the Service have been copied in a way that
        constitutes copyright infringement, you may submit a notification
        pursuant to the Digital Millennium Copyright Act (DMCA) by providing our
        copyright agent with the following information in writing (see 17 U.S.C
        512(c)(3) for further detail): 1; an electronic or physical signature of
        the person authorized to act on behalf of the owner of the copyright’s
        interest; 2; a description of the copyrighted work that you claim has
        been infringed; 3; identification of the allegedly infringing material
        on the Service, including URL or other specific location on the Service
        where the material that you claim is infringing is located; 4; your
        address, telephone number, and email address; 5; a statement by you that
        you have a good faith belief that the disputed use is not authorized by
        the copyright owner, its agent, or the law; 6; a statement by you, made
        under penalty of perjury, that the above information in your notice is
        accurate and that you are the copyright owner or authorized to act on
        the copyright owner’s behalf.
        <h4 className="text-xl font-medium font-heading text-gray-600 my-3">
          Termination
        </h4>
        We may terminate or suspend your account and bar access to the Service
        immediately, without prior notice or liability, under our sole
        discretion, for any reason whatsoever and without limitation, including
        but not limited to a breach of the Terms. If you wish to terminate your
        account, you may do so by contacting us at support@quizme.ai After 30 days,
        the Content will be deleted from our servers and can no longer be
        recovered. If your account is terminated by us - you will (on request)
        be sent a copy of all of your work in Quiz Me Ai in machine readable
        form. (this is not guaranteed if termination is at your request).
        <h4 className="text-xl font-medium font-heading text-gray-600 my-3">
          Limitation of Liability
        </h4>
        IN NO EVENT SHALL QUIZ ME AI, NOR ITS DIRECTORS, EMPLOYEES, PARTNERS,
        AGENTS, SUPPLIERS, OR AFFILIATES, BE LIABLE FOR ANY INDIRECT,
        INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING
        WITHOUT LIMITATION, LOSS OF PROFITS, DATA, USE, GOODWILL, OR OTHER
        INTANGIBLE LOSSES, RESULTING FROM (A) YOUR ACCESS TO OR USE OF OR
        INABILITY TO ACCESS OR USE THE SERVICE; (B) ANY CONDUCT OR CONTENT OF
        ANY THIRD PARTY ON THE SERVICE; (C) ANY CONTENT OBTAINED FROM THE
        SERVICE; AND (D) UNAUTHORIZED ACCESS, USE OR ALTERATION OF YOUR
        TRANSMISSIONS OR CONTENT, WHETHER BASED ON WARRANTY, CONTRACT, TORT
        (INCLUDING NEGLIGENCE) OR ANY OTHER LEGAL THEORY, WHETHER OR NOT WE HAVE
        BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, AND EVEN IF A REMEDY
        SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.
        <h4 className="text-xl font-medium font-heading text-gray-600 my-3">
          Disclaimer
        </h4>
        YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS PROVIDED ON
        AN “AS IS” AND “AS AVAILABLE” BASIS. THE SERVICE IS PROVIDED WITHOUT
        WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT
        LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
        PARTICULAR PURPOSE, NON-INFRINGEMENT OR COURSE OF PERFORMANCE. QUIZ ME
        AI ITS SUBSIDIARIES, ITS AFFILIATES, AND ITS LICENSORS DO NOT WARRANT
        THAT (A) THE SERVICE WILL FUNCTION UNINTERRUPTED, SECURE OR AVAILABLE AT
        ANY PARTICULAR TIME OR LOCATION; (B) ANY ERRORS OR DEFECTS WILL BE
        CORRECTED; (C) THE SERVICE IS FREE OF VIRUSES OR OTHER HARMFUL
        COMPONENTS; OR (D) THE RESULTS OF USING THE SERVICE WILL MEET YOUR
        REQUIREMENTS.
      </div>
    </div>
  )
}
