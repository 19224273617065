import * as React from "react"

import Layout from "../components/layout"

import Seo from "../components/seo"
import Tos from "../components/tos"

const TosPage = () => (
  <Layout>
    <Seo title="Terms of Service" />
    <Tos />
  </Layout>
)

export default TosPage
